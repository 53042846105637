


import {
    ref, onMounted,
    onActivated,
    onDeactivated,
    onUnmounted,
    SetupContext,
} from "vue";


import { throttle } from "@/utils/utils";
import $ from "jquery";

export class Queue<T> {
    private front: number;
    private rear: number;
    private data: T[]
    constructor() {
        this.front = 0;
        this.rear = 0;
        this.data = []
    }
    enQueue(el: T) {
        this.data.push(el)
        if (!this.isEmptyQueue()) {
            this.rear++
        }
    }
    enFrontQueue(el: T) {
        this.data.unshift(el)
        if (!this.isEmptyQueue()) {
            this.rear++
        }
    }
    outQueue() {
        if (!this.isEmptyQueue()) {
            return this.data.shift()
        }
    }
    outRearQueue() {
        if (!this.isEmptyQueue()) {
            return this.data.pop()
        }
    }

    removeAtPos(pos: number) {
        if (!this.isEmptyQueue()) {
            const data = this.data.slice(0)
            this.clear()
            data.splice(pos, 1)
            data.forEach(item => {
                this.enQueue(item)
            })
        }
    }

    getFrontElement() {
        return this.data[this.front]
    }
    getRearElement() {
        return this.data[this.rear]
    }
    isEmptyQueue() {
        return this.size() === 0
    }
    size() {
        return this.data.length
    }
    value() {
        return this.data
    }
    clear() {
        this.data = []
        this.rear = this.front = 0
    }
}

interface TabOption<T> {
    menu: T[];
    initIndex?: number;
    selector?: string;
    fixFunc?: (sticky: boolean) => void;
}



const usePageTab = <P, T>(ctx: SetupContext<"change"[]>, options: TabOption<T>) => {

    if (options.selector) {

        //  sticky station
        const scroll = function () {
            const dom = $((options.selector) as string) as any;
            try {
                if (!dom.length) {
                    return false
                }
                const navTop = dom.offset().top - dom.height()
                const scrollTop = $(this).scrollTop();
                //  华为p30 会出现缝隙 
                options.fixFunc && options.fixFunc(Number(scrollTop) > Number(navTop) + 5)
            } catch (err) {
                console.log(err)
            }

        }
        onMounted(() => {
            $(window).on("scroll", scroll);
        });
        onActivated(() => {
            $(window).on("scroll", scroll);
        });
        onDeactivated(() => {
            $(window).off("scroll", scroll);
        });
        onUnmounted(() => {
            $(window).off("scroll", scroll);
        });
    }


    const clickItemMap = new Map();

    options.menu.forEach((item) => {
        clickItemMap.set(item, 0);
    });

    //  the queue is record operation
    const menuQueue = new Queue<T>();

    function switchMenu(menuItem: T, navTop: number) {

        const scrollTop = clickItemMap.get(menuItem);
        clickItemMap.set(menuQueue.getFrontElement(), $(window).scrollTop());
        if (!menuQueue.isEmptyQueue()) {
            menuQueue.outQueue();
        }

        menuQueue.enQueue(menuItem);
        // if (!scrollTop) {

        //     $(window).scrollTop(navTop);
        // } else {
        //     $(window).scrollTop(scrollTop);
        // }
        if (scrollTop) {
            $(window).scrollTop(scrollTop);
        }
    }
    const currentMenuItem = ref<T>(options.menu[options.initIndex || 0]);

    function selectMenu(menuItem: T) {
        if (options.selector) {
            switchMenu(menuItem, (($((options.selector) as string) as any).offset().top) - ($((options.selector) as string) as any).height())
        }

        (currentMenuItem.value as T) = menuItem;
        ctx.emit('change', menuItem)
    }
    function updateCurrentMenuItem(index: number) {
        currentMenuItem.value = options.menu[index] as any
    }




    return {
        currentMenuItem,
        selectMenu,
        updateCurrentMenuItem
    }

}




export default usePageTab