
import {
  defineComponent,
  onMounted,
  nextTick,
  computed,
  ref,
  watch,
  onActivated,
} from "vue";
import usePageTab from "@/hooks/usePageTab";
import $ from "jquery";
import Swiper from "swiper";
export default defineComponent({
  name: "MenuTab",
  emits: ["change"],
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    swiper: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    initIndex: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    slidesPerView: {
      type: Number,
      default: () => {
        return 4;
      },
    },
  },
  setup(props, ctx) {
    let swiper: null | Swiper = null;

    const menuTabWidth = computed(() => {
      const width = Number.isNaN(props.slidesPerView)
        ? "25%"
        : Number(100 / Number(props.slidesPerView)).toFixed(2) + "%";
      return width;
    });
    function fixed(sticky: boolean) {
      if (sticky) {
        $(".menu-nav").css({
          position: "fixed",
          top: "50px",
        });
      } else {
        $(".menu-nav").css({
          position: "static",
        });
      }
    }
    const { currentMenuItem, selectMenu, updateCurrentMenuItem } = usePageTab(
      ctx,
      {
        menu: props.menu,
        selector: ".menu-nav-wapper",
        fixFunc: fixed,
        initIndex: props.initIndex,
      }
    );
    onMounted(() => {
      if (props.swiper) {
        swiper = new Swiper("#menu-nav-swiper", {
          initialSlide: props.initIndex,
          slidesPerView: props.slidesPerView,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
        });
      }
    });
    return {
      currentMenuItem,
      selectMenu,
      menuTabWidth,
    };
  },
});
